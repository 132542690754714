import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

import luzernImageUrl from "../images/Luzern.jpg";

const Image = styled.img`
  width: 250px;
  height: 200px;
  object-fit: cover;
  border:  1px solid var(--color-primary-border);
`;

export default () => (
  <Layout>
    <h3>Unsere Partner</h3>
    <Image src={luzernImageUrl} alt="Luzern" />
    <Image src={luzernImageUrl} alt="Luzern" />
    <Image src={luzernImageUrl} alt="Luzern" />
    <Image src={luzernImageUrl} alt="Luzern" />
    <Image src={luzernImageUrl} alt="Luzern" />
    <Image src={luzernImageUrl} alt="Luzern" />
  </Layout>
);